"use strict";
var BlkPageIndexInit = function () {
    var Header = document.querySelector('.l-Navigation');
    var SpHeader = document.querySelector('.l-SpMenu');
    var anchors = document.querySelectorAll('.blk-page-index__link');
    anchors.forEach(function (anchor) {
        anchor.addEventListener('click', function (event) {
            event.preventDefault();
            var href = anchor.href;
            var hash = new URL(href).hash;
            if (hash) {
                var target = document.querySelector(hash);
                if (target) {
                    var headerHeight = (SpHeader && SpHeader.clientHeight > 0) ? SpHeader.clientHeight : (Header ? Header.clientHeight : 0);
                    window.scrollTo({
                        top: window.scrollY + target.getBoundingClientRect().top - headerHeight,
                        behavior: 'smooth',
                    });
                }
            }
            else {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                });
            }
        }, {});
    });
};
window.addEventListener('DOMContentLoaded', BlkPageIndexInit, {});
